@tailwind  base;

@tailwind  components;

body {
    @apply font-gill-medium;
}

@media (min-width: 1480px) {
    .container {/*container width in invision design for desktop: 1440px*/
        max-width: 1440px;
    }
}

h1, .h1 {
    @apply font-brooklyn-bold uppercase leading-none;
}

h2, .h2 {
    @apply text-4xl font-brooklyn-bold text-petrol-500 uppercase leading-none;
}

h3, .h3 {
    @apply text-2xl font-brooklyn-bold;
}

svg.location tspan {/*needed for location names in svg map*/
    @apply font-brooklyn-bold;
}

.bottom-20 {
    bottom: 20px;
}

.right-20 {
    right: 20px;
}

.logo-wrapper {
    background: rgb(50,180,50);
    background: linear-gradient(90deg, rgba(50,180,50,1) 0%, rgba(0,92,102,1) 100%);

    min-width: 132px;
    width: 168px;
    height: 90px;
}

.text-shadow {
    text-shadow: 0 2px 2px rgba(0,0,0,0.60);
}

.location {
    transform: scale(1.4);
    max-height: 512px;/*needed for correct height in firefox (otherwise too large)*/
}

.target-offset:target::before {
    content: ' ';
    display: block;
    height: 64px;
    width: 1px;
}

/*utilities should always be added last*/
@tailwind utilities;

